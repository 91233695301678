export const state = () => ({
    scrollY: 0,
    windowWidth: 0,
    prevWindowWidth: 0,
    isSearchDropdownOpen: false,
    isMobileMenuOpen: false,
    isHeaderThin: false,
    isPageLoading: true,
    isLoginModalOpen: false,
    isRegistrationModalOpen: false,
    lastScrollDirection: 'down',
    notifications: [],
    editable_options: [
        {
            id: 1,
            name: 'frame',
            options: [
                {
                    id: 1,
                    name: 'Fekete',
                    preview: 'frame_1.webp',
                    color: '#00000',
                    image: '',
                    price: 2000,
                },
                {
                    id: 2,
                    name: 'Fehér',
                    preview: 'frame_2.webp',
                    color: '#ffffff',
                    image: '',
                    price: 2000,
                },
                {
                    id: 3,
                    name: 'Fa  (Tölgy)',
                    preview: 'frame_3.webp',
                    color: '#ffffff',
                    image: '',
                    price: 2000,
                },
                {
                    name: 'Nem kérek keretet',
                    preview: '',
                    color: '#ffffff',
                    image: '',
                    price: 2000,
                },
            ],
        },
        {
            id: 2,
            name: 'size',
            options: [
                {
                    id: 1,
                    name: '10*20cm (A5)',
                    width: 14.8,
                    height: 21,
                    preview: -1000,
                },
                {
                    id: 2,
                    name: '20*30cm (A4)',
                    width: 21,
                    height: 29.7,
                    preview: '',
                    price: 0,
                },
                {
                    id: 3,
                    name: '40*60cm (A3)',
                    width: 29.7,
                    height: 42,
                    preview: '',
                    price: 3000,
                },
            ],
        },
    ],
});

export const getters = {
    getWindowWidth(state) {
        return state.windowWidth;
    },
};

export const mutations = {
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
    setHeaderThin(state, payload) {
        state.isHeaderThin = payload;
    },
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    setLastScrollDirection(state, payload) {
        state.lastScrollDirection = payload;
    },
    toggleLoginModal(state, payload) {
        state.isLoginModalOpen = payload;
    },
    toggleRegistrationModal(state, payload) {
        state.isRegistrationModalOpen = payload;
    },
    setIsPageLoading(state, payload) {
        state.isPageLoading = payload;
    },
    setNotifications(state, payload) {
        state.notifications.push(payload);
    },
    emptyNotifications(state) {
        state.notifications = [];
    },
    toggleSearchDropdown(state, payload) {
        state.isSearchDropdownOpen = payload;
    },
    toggleMobileMenu(state, payload) {
        state.isMobileMenuOpen = payload;
    },
    setPrevWindowWidth(state, payload) {
        state.prevWindowWidth = payload;
    },
};
