var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10 flex flex-col justify-center pb-8 mt-14 border-b border-black border-dashed border-opacity-20"},[_c('h2',{staticClass:"text-[1.6rem] lg:text-4xl font-Inter-Bold",class:{
            'lg:text-[2.25rem] mb-1': _vm.xl && !_vm.lg && !_vm.md,
            'lg:text-[2.25rem]': _vm.lg && !_vm.md,
            'lg:text-3xl': !_vm.lg && !_vm.md,
            'text-left lg:text-2xl': _vm.md,
            'text-left': _vm.alignLeft,
            'text-center': !_vm.alignLeft,
        }},[_vm._v("\n        "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),(_vm.subtitle)?_c('h3',{staticClass:"text-center text-sm lg:text-lg mt-5 w-full lg:w-7/12 lg:mx-auto font-Inter-Regular opacity-50"},[_vm._v("\n        "+_vm._s(_vm.subtitle)+"\n    ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }