import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_nuxtgooglemaps_f8072760 from 'nuxt_plugin_nuxtgooglemaps_f8072760' // Source: ./nuxt-google-maps.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_d5962862 from 'nuxt_plugin_cookieuniversalnuxt_d5962862' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_recaptcha_54339d16 from 'nuxt_plugin_recaptcha_54339d16' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_dayjsplugin_c4421654 from 'nuxt_plugin_dayjsplugin_c4421654' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_axios_82e30bb2 from 'nuxt_plugin_axios_82e30bb2' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_1af4a6f4 from 'nuxt_plugin_pluginutils_1af4a6f4' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_33317182 from 'nuxt_plugin_pluginrouting_33317182' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_2223f6e0 from 'nuxt_plugin_pluginmain_2223f6e0' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_image_acd511bc from 'nuxt_plugin_image_acd511bc' // Source: ./image.js (mode: 'all')
import nuxt_plugin_deviceplugin_cf0e0a9c from 'nuxt_plugin_deviceplugin_cf0e0a9c' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_googleanalytics_6558cf9f from 'nuxt_plugin_googleanalytics_6558cf9f' // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_i18n_bcd7274e from 'nuxt_plugin_i18n_bcd7274e' // Source: ../plugins/lib/i18n.js (mode: 'all')
import nuxt_plugin_axios_51943081 from 'nuxt_plugin_axios_51943081' // Source: ../plugins/lib/axios.js (mode: 'all')
import nuxt_plugin_persistedstate_cd97bcc0 from 'nuxt_plugin_persistedstate_cd97bcc0' // Source: ../plugins/lib/persisted-state.js (mode: 'all')
import nuxt_plugin_dayjs_38e10e9c from 'nuxt_plugin_dayjs_38e10e9c' // Source: ../plugins/lib/dayjs.js (mode: 'all')
import nuxt_plugin_primevue_1227f47f from 'nuxt_plugin_primevue_1227f47f' // Source: ../plugins/lib/prime-vue.js (mode: 'all')
import nuxt_plugin_veevalidate_60dc9aac from 'nuxt_plugin_veevalidate_60dc9aac' // Source: ../plugins/lib/vee-validate.js (mode: 'all')
import nuxt_plugin_vueportal_66bbc078 from 'nuxt_plugin_vueportal_66bbc078' // Source: ../plugins/lib/vue-portal.js (mode: 'all')
import nuxt_plugin_swiper_3223d082 from 'nuxt_plugin_swiper_3223d082' // Source: ../plugins/lib/swiper.js (mode: 'all')
import nuxt_plugin_htmlcanvasclient_2bf0ac58 from 'nuxt_plugin_htmlcanvasclient_2bf0ac58' // Source: ../plugins/lib/html-canvas.client.js (mode: 'client')
import nuxt_plugin_lodash_78ae8b82 from 'nuxt_plugin_lodash_78ae8b82' // Source: ../plugins/lib/lodash.js (mode: 'all')
import nuxt_plugin_vuenumberanimationclient_70f64745 from 'nuxt_plugin_vuenumberanimationclient_70f64745' // Source: ../plugins/lib/vue-number-animation.client.js (mode: 'client')
import nuxt_plugin_init_5aefaeb1 from 'nuxt_plugin_init_5aefaeb1' // Source: ../plugins/init.js (mode: 'all')
import nuxt_plugin_initclient_ad36b038 from 'nuxt_plugin_initclient_ad36b038' // Source: ../plugins/init.client.js (mode: 'client')
import nuxt_plugin_utils_1ea7651c from 'nuxt_plugin_utils_1ea7651c' // Source: ../plugins/utils.js (mode: 'all')
import nuxt_plugin_helpers_1c816d70 from 'nuxt_plugin_helpers_1c816d70' // Source: ../plugins/helpers.js (mode: 'all')
import nuxt_plugin_globalcomponents_52ce1226 from 'nuxt_plugin_globalcomponents_52ce1226' // Source: ../plugins/global-components.js (mode: 'all')
import nuxt_plugin_directivesclient_62899358 from 'nuxt_plugin_directivesclient_62899358' // Source: ../plugins/directives.client.js (mode: 'client')
import nuxt_plugin_forwardsetcookie_77625cc0 from 'nuxt_plugin_forwardsetcookie_77625cc0' // Source: ../plugins/lib/forward-set-cookie.js (mode: 'server')
import nuxt_plugin_googleanalytics_40fcf844 from 'nuxt_plugin_googleanalytics_40fcf844' // Source: ../static/scripts/google-analytics.js (mode: 'client')
import nuxt_plugin_auth_1dcc63ae from 'nuxt_plugin_auth_1dcc63ae' // Source: ./auth.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: function() {
    const script = [];

    if (process.env.NUXT_NODE_ENV === 'production') {
      const SCRIPT_PATHS = ['/scripts/meta-pixel.js', '/scripts/google-analytics.js'];

      SCRIPT_PATHS.forEach((src) => {
        script.push({
          src,
          async: true });
      });
    }

    script.push({
      src: '/scripts/body-scroll-lock.min.js' });

    return {
      title: 'Kartapolis',
      meta: [
      //
      //
      // Basics
      { charset: 'utf-8' },
      { property: 'og:type', content: 'website' },
      { name: 'format-detection', content: 'telephone=no' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=5' },
      { property: 'og:site_name', content: 'Kartapolis' },
      { name: 'msapplication-TileColor', content: '#FAFAFA' },
      { name: 'theme-color', content: '#FAFAFA' },
      //
      //
      // Robots: comment back the first line only when the site is on the actual production domain
      // { name: 'robots', content: process.env.NUXT_NODE_ENV === 'production' ? 'all' : 'noindex nofollow' },
      {
        name: 'robots',
        content: 'noindex nofollow' },

      //
      //
      // Defaults
      {
        hid: 'title',
        name: 'title',
        content: 'Kartapolis' },

      {
        hid: 'og:title',
        property: 'og:title',
        content: 'Kartapolis' },

      {
        hid: 'og:image',
        property: 'og:image',
        content: '/og-image.jpg' },

      {
        hid: 'og:image:url',
        property: 'og:image:url',
        content: '/og-image.jpg' },

      {
        hid: 'og:image:type',
        property: 'og:image:type',
        content: 'image/jpg' }

      // Only static meta tags are handled here.
      // The rest (stuff that needs localization or the $route object) is handled in the meta mixin
      ],
      link: [
      { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
      { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
      { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
      { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
      { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#ED2E39' },
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap' }],

      script };
  },

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_nuxtgooglemaps_f8072760 === 'function') {
    await nuxt_plugin_nuxtgooglemaps_f8072760(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_d5962862 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_d5962862(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_54339d16 === 'function') {
    await nuxt_plugin_recaptcha_54339d16(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_c4421654 === 'function') {
    await nuxt_plugin_dayjsplugin_c4421654(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_82e30bb2 === 'function') {
    await nuxt_plugin_axios_82e30bb2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_1af4a6f4 === 'function') {
    await nuxt_plugin_pluginutils_1af4a6f4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_33317182 === 'function') {
    await nuxt_plugin_pluginrouting_33317182(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_2223f6e0 === 'function') {
    await nuxt_plugin_pluginmain_2223f6e0(app.context, inject)
  }

  if (typeof nuxt_plugin_image_acd511bc === 'function') {
    await nuxt_plugin_image_acd511bc(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_cf0e0a9c === 'function') {
    await nuxt_plugin_deviceplugin_cf0e0a9c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_6558cf9f === 'function') {
    await nuxt_plugin_googleanalytics_6558cf9f(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_bcd7274e === 'function') {
    await nuxt_plugin_i18n_bcd7274e(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_51943081 === 'function') {
    await nuxt_plugin_axios_51943081(app.context, inject)
  }

  if (typeof nuxt_plugin_persistedstate_cd97bcc0 === 'function') {
    await nuxt_plugin_persistedstate_cd97bcc0(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_38e10e9c === 'function') {
    await nuxt_plugin_dayjs_38e10e9c(app.context, inject)
  }

  if (typeof nuxt_plugin_primevue_1227f47f === 'function') {
    await nuxt_plugin_primevue_1227f47f(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_60dc9aac === 'function') {
    await nuxt_plugin_veevalidate_60dc9aac(app.context, inject)
  }

  if (typeof nuxt_plugin_vueportal_66bbc078 === 'function') {
    await nuxt_plugin_vueportal_66bbc078(app.context, inject)
  }

  if (typeof nuxt_plugin_swiper_3223d082 === 'function') {
    await nuxt_plugin_swiper_3223d082(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_htmlcanvasclient_2bf0ac58 === 'function') {
    await nuxt_plugin_htmlcanvasclient_2bf0ac58(app.context, inject)
  }

  if (typeof nuxt_plugin_lodash_78ae8b82 === 'function') {
    await nuxt_plugin_lodash_78ae8b82(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuenumberanimationclient_70f64745 === 'function') {
    await nuxt_plugin_vuenumberanimationclient_70f64745(app.context, inject)
  }

  if (typeof nuxt_plugin_init_5aefaeb1 === 'function') {
    await nuxt_plugin_init_5aefaeb1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_initclient_ad36b038 === 'function') {
    await nuxt_plugin_initclient_ad36b038(app.context, inject)
  }

  if (typeof nuxt_plugin_utils_1ea7651c === 'function') {
    await nuxt_plugin_utils_1ea7651c(app.context, inject)
  }

  if (typeof nuxt_plugin_helpers_1c816d70 === 'function') {
    await nuxt_plugin_helpers_1c816d70(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_52ce1226 === 'function') {
    await nuxt_plugin_globalcomponents_52ce1226(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_directivesclient_62899358 === 'function') {
    await nuxt_plugin_directivesclient_62899358(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_forwardsetcookie_77625cc0 === 'function') {
    await nuxt_plugin_forwardsetcookie_77625cc0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_40fcf844 === 'function') {
    await nuxt_plugin_googleanalytics_40fcf844(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_1dcc63ae === 'function') {
    await nuxt_plugin_auth_1dcc63ae(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
