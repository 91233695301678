export default {
    cart: [],
    liked_products: [],
    invoice_details: {
        last_name: null,
        first_name: null,
        email: null,
        phone: null,
        country: null,
        zip_code: null,
        city: null,
        address: null,
    },
    checkout_details: {
        aszf: null,
        billing_address: null,
        billing_city: null,
        billing_first_name: null,
        billing_last_name: null,
        billing_postal_code: null,
        email: null,
        phone: null,
        shipping_address: null,
        shipping_city: null,
        shipping_first_name: null,
        shipping_last_name: null,
        shipping_postal_code: null,
        message: null,
        selected_shipping_method: null,
        selected_payment_method: null,
        is_loading: false,
        foxpost_point: null,
    },
    shipping_details: {
        last_name: null,
        first_name: null,
        email: null,
        phone: null,
        country: null,
        zip_code: null,
        city: null,
        address: null,
    },
    shipping_methods: [],
    checkout_errors: {},
};
